import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Order } from 'Types/global.types.ts'
import { UserTypes } from 'Types/user.types.ts'
import { AdminPaymentType, CustomersSortType, CustomFileType } from 'Types/admin/admin'

import {
  addExternalCustomer,
  getCustomerCustomFiles,
  getCustomerList,
  getCustomerListIncludingExternal,
  getExternalCustomerList,
  getPaymentHistoryList,
  removeCustomer,
  searchCustomers,
  toggleBlockingCustomer,
  updateCustomer,
  updateCustomerPaymentType,
  updateCustomerStatus,
  updateExternalCustomer,
  uploadCustomerCustomFile,
  uploadCustomerDocument,
} from 'Api/admin/admin.customers.service.ts'

interface InitialStateProps {
  customerList: UserTypes[]
  initialCustomerList: UserTypes[]
  loading: boolean
  total: number
  pageSize: number
  customerCustomFiles: CustomFileType[]
  customFilesTotal: number
  sort: CustomersSortType
  onboardingStatus: 'ALL' | 'FINISHED' | 'UNFINISHED'
  order: 'ASC' | 'DESC'
  param: string
  paymentHistory: AdminPaymentType[]
  paymentHistoryTotal: number
  customerListIncludingExternal: UserTypes[]
  customerListIncludingExternalTotal: number
  customerListIncludingExternalPageSize: number
  customerListIncludingExternalPageIndex: number
  customerListIncludingExternalPageLoading: boolean
  customerListIncludingExternalPageParam: string
  externalCustomerList: UserTypes[]
  externalCustomerListTotal: number
  externalCustomerListPageSize: number
  externalCustomerListPageIndex: number
  externalCustomerListLoading: boolean
  externalCustomerListParam: string
  externalCustomerListSort: CustomersSortType
  externalCustomerListOrder: 'ASC' | 'DESC'
}

const initialState: InitialStateProps = {
  customerList: [],
  initialCustomerList: [],
  loading: false,
  total: 0,
  pageSize: 20,
  customerCustomFiles: [],
  customFilesTotal: 0,
  sort: 'CREATED_AT',
  onboardingStatus: 'ALL',
  order: 'DESC',
  param: '',
  paymentHistory: [],
  paymentHistoryTotal: 0,
  customerListIncludingExternal: [],
  customerListIncludingExternalTotal: 0,
  customerListIncludingExternalPageSize: 20,
  customerListIncludingExternalPageIndex: 0,
  customerListIncludingExternalPageLoading: false,
  customerListIncludingExternalPageParam: '',
  externalCustomerList: [],
  externalCustomerListTotal: 0,
  externalCustomerListPageSize: 20,
  externalCustomerListPageIndex: 0,
  externalCustomerListLoading: false,
  externalCustomerListParam: '',
  externalCustomerListSort: 'CREATED_AT',
  externalCustomerListOrder: 'DESC',
}

const customerListSlice = createSlice({
  name: 'customerList',
  initialState,
  reducers: {
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
    },
    setParamExternalCustomerList: (state, action: PayloadAction<string>) => {
      state.externalCustomerListParam = action.payload
    },
    setOrder: (state, action: PayloadAction<Order>) => {
      if (action.payload) {
        state.order = action.payload
      }
    },
    setSort: (state, action: PayloadAction<CustomersSortType>) => {
      if (action.payload) {
        state.sort = action.payload
      }
    },
    setExternalCustomerListSort: (state, action: PayloadAction<CustomersSortType>) => {
      if (action.payload) {
        state.externalCustomerListSort = action.payload
      }
    },
    setExternalCustomerListOrder: (state, action: PayloadAction<Order>) => {
      if (action.payload) {
        state.externalCustomerListOrder = action.payload
      }
    },
    setOnboardingStatus: (state, action) => {
      if (action.payload) {
        state.onboardingStatus = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = action.payload.customerList
          state.initialCustomerList = action.payload.customerList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomerList.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadCustomerDocument.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            } else {
              return customer
            }
          })
        }
      })
      .addCase(toggleBlockingCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
      .addCase(searchCustomers.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = action.payload.customerList
          state.initialCustomerList = action.payload.customerList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(searchCustomers.pending, (state) => {
        state.loading = true
      })
      .addCase(searchCustomers.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeCustomer.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.customerList = state.customerList.filter((customer) => customer.id !== action.payload.customerId)
        }
      })
      .addCase(getCustomerCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerCustomFiles = action.payload.customerList
          state.customFilesTotal = action.payload.total
        }
      })
      .addCase(uploadCustomerCustomFile.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerCustomFiles.unshift(action.payload.customFile)
          state.customFilesTotal += 1
        }
      })
      .addCase(updateCustomerPaymentType.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customerList = state.customerList.map((customer) => {
          if (customer.id === action.payload.customer.id) {
            return action.payload.customer
          }
          return customer
        })
      })
      .addCase(getPaymentHistoryList.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentHistory = action.payload.paymentHistory
          state.paymentHistoryTotal = action.payload.total
        }
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerList = state.customerList.map((customer) => {
            if (customer.id === action.payload.customer.id) {
              return action.payload.customer
            }
            return customer
          })
        }
      })
      .addCase(getCustomerListIncludingExternal.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerListIncludingExternal = action.payload.customerListIncludingExternal
          state.customerListIncludingExternalTotal = action.payload.total
        }
        state.customerListIncludingExternalPageLoading = false
      })
      .addCase(getCustomerListIncludingExternal.pending, (state) => {
        state.customerListIncludingExternalPageLoading = true
      })
      .addCase(getCustomerListIncludingExternal.rejected, (state) => {
        state.customerListIncludingExternalPageLoading = false
      })
      .addCase(getExternalCustomerList.fulfilled, (state, action) => {
        if (action.payload) {
          state.externalCustomerList = action.payload.externalCustomerList
          state.externalCustomerListTotal = action.payload.total
        }
        state.externalCustomerListLoading = false
      })
      .addCase(getExternalCustomerList.pending, (state) => {
        state.externalCustomerListLoading = true
      })
      .addCase(getExternalCustomerList.rejected, (state) => {
        state.externalCustomerListLoading = false
      })
      .addCase(addExternalCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerListIncludingExternal.unshift(action.payload.externalCustomer)
          state.customerListIncludingExternalTotal += 1
          state.externalCustomerList.unshift(action.payload.externalCustomer)
          state.externalCustomerListTotal += 1
        }
      })
      .addCase(updateExternalCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerListIncludingExternal = state.customerListIncludingExternal.map((customer) => {
            if (customer.id === action.payload.externalCustomer.id) {
              return action.payload.externalCustomer
            }
            return customer
          })
          state.externalCustomerList = state.externalCustomerList.map((customer) => {
            if (customer.id === action.payload.externalCustomer.id) {
              return action.payload.externalCustomer
            }
            return customer
          })
        }
      })
  },
})

const { actions } = customerListSlice
export const {
  setSort,
  setOrder,
  setParam,
  setOnboardingStatus,
  setParamExternalCustomerList,
  setExternalCustomerListOrder,
  setExternalCustomerListSort,
} = actions
export default customerListSlice.reducer
